@use '/src/styles/partials/_colors.scss' as colors;
@use '/src/styles/partials/_shadow.scss' as shadow;
@use '/src/styles/partials/_typography.scss' as typo;
@use '/src/styles/partials/_responsive.scss' as *;

.button {
  text-align: center;
  border: none;
  color: colors.$white-normal;
  cursor: pointer;
  transition: all 0.3s ease-out;
  text-decoration: none;
  user-select: none;

  &.size-sm {
    @include typo.typeface(p3);
    border-radius: 0.25rem;
    padding: 0.5rem 0.725rem;
  }
  &.size-md {
    @include typo.typeface(p2);
    border-radius: 0.25rem;
    padding: 0.75rem 1.25rem;
  }
  &.size-lg {
    @include typo.typeface(p2);
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
  }

  &.variant-primary {
    &.outline {
      background: transparent;
      background-color: transparent;
      border-width: 1px;
      border-style: solid;
      border-color: colors.$purple;
    }

    &:not(.outline) {
      @include colors.gradient-2();
    }

    &:hover,
    &.active {
      @include colors.gradient-1();
      box-shadow: shadow.$shadow-btn-primary;

      &.outline {
        border-color: transparent;
      }
    }
  }

  &.variant-secondary {
    &.outline {
      background: transparent;
      background-color: transparent;
      border-width: 1px;
      border-style: solid;
      border-color: colors.$gray-5;
    }

    &:not(.outline) {
      @include colors.gradient-5();
      border: 1px solid colors.$gray-3;
    }

    &:hover,
    &.active {
      border: 1px solid colors.$gray-5;
      box-shadow: shadow.$shadow-btn-secondary;

      &.outline {
        @include colors.gradient-5();
      }
    }
  }

  &.disabled,
  &.loading {
    pointer-events: none;
    cursor: not-allowed;
    filter: grayscale(0.8);
  }
}
