@use '/src/styles/partials/_colors.scss' as colors;
@use '/src/styles/partials/_typography.scss' as typo;
@use '/src/styles/partials/_responsive.scss' as *;
@use '/src/styles/partials/_helpers.scss' as *;
@use '/src/styles/partials/_lists.scss' as *;

@mixin linkStyle() {
  color: colors.$gray-6;
  text-decoration: none;
  transition: all 0.2s;
  @include typo.typeface(p3, desktop);
  white-space: nowrap;
  font-weight: 300;

  svg {
    margin-left: 0.25rem;
    color: colors.$purple-dark;
    transition: all 0.2s;
  }

  &:hover {
    color: colors.$white-light;
    cursor: pointer;

    svg {
      color: colors.$purple-light;
    }
  }
}

.footer {
  display: flex;
  position: relative;
  margin-top: 5rem !important;
  margin-bottom: 2.5rem !important;
  padding-top: 3rem;
  flex-direction: column;
  gap: 3.5rem;
  width: 100%;

  @include media('<sm') {
    gap: 1.5rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: linear-gradient(
      to right,
      transparent 0%,
      colors.$gray-2 25%,
      colors.$gray-5 50%,
      colors.$gray-2 75%,
      transparent 100%
    );
  }

  .links {
    display: flex;
    width: 100%;
    justify-content: space-around;

    @include media('<sm') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      text-align: center;
    }

    a {
      @include linkStyle();
    }
  }

  > p {
    font-size: 90%;
    color: colors.$gray-5;
    font-weight: 400;
    margin-bottom: 0;
    text-align: center;
  }

  .language {
    position: relative;
    z-index: 20;
    text-align: center;
    @include flexCenter();

    button {
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      @include linkStyle();
    }

    ul {
      @include list() {
        position: absolute;
        bottom: calc(100% + 0.5rem);
        width: max-content;
        transform: translate(-50%, 0) !important;
      }
    }
  }
}
