@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import 'partials/responsive';
@import 'partials/colors';
@import 'partials/typography';
@import 'partials/variables';

*,
*:after,
*:before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  // Scrool mais suave de transição
  scroll-behavior: smooth;
}
body {
  width: 100%;
  height: 100vh;
  position: relative;
  scroll-behavior: smooth;
  background-color: $background !important;
  color: $white-normal !important;
  font-family: 'Roboto', sans-serif !important;
}

svg,
img {
  width: 100%;
}

h1 {
  @include typeface(h1, desktop);
  @include media('<md') {
    @include typeface(h1, mobile);
  }
}

h2 {
  @include typeface(h2, desktop);
  @include media('<md') {
    @include typeface(h2, mobile);
  }
}

h3 {
  @include typeface(h3, desktop);
  @include media('<md') {
    @include typeface(h3, mobile);
  }
}

h4 {
  @include typeface(h4, desktop);
  @include media('<md') {
    @include typeface(h4, mobile);
  }
}

h5 {
  @include typeface(h5, desktop);
  @include media('<md') {
    @include typeface(h5, mobile);
  }
}

p,
strong {
  margin-bottom: 0;

  @include typeface(p3, desktop);
  @include media('<md') {
    @include typeface(p3, mobile);
  }
}

strong {
  font-weight: bold;
}

.content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

::selection {
  color: $black-dark !important;
  background: $purple-light;
  text-shadow: none;
}

::-webkit-scrollbar-track {
  background: $black-dark;
  box-shadow: 0 0 2px 0 $purple-light inset;
}
::-webkit-scrollbar-thumb {
  background: $purple;
  border-radius: 0.25rem;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
::-webkit-scrollbar {
  -webkit-appearance: none;

  &:vertical {
    width: 0.3rem;
  }

  &:horizontal {
    height: 0.3rem;
  }
}

a {
  color: $gray-6;
  text-decoration: none;
  transition: all 0.2s;
  white-space: nowrap;
  font-weight: 300;

  &:hover {
    color: $white-light;
    cursor: pointer;
  }
}

@import 'layout.scss';

#nprogress {
  .bar {
    background: $purple !important;
    top: calc($navSize + 1px) !important;
  }
  .peg {
    box-shadow: 0 0 10px $purple, 0 0 5px $purple !important;
    transform: none !important;
    width: 100% !important;
  }
  .spinner-icon {
    border-top-color: $purple !important;
    border-left-color: $purple !important;
  }
}
