// Typography of project

@mixin typeface($size, $device: desktop) {
  font-family: 'Roboto', sans-serif;

  @if $size == h1 and $device == desktop {
    // H1 DESKTOP
    font-size: 6rem;
    line-height: 90%;
  } @else if $size == h1 and $device == mobile {
    // H1 MOBILE
    font-size: 3.5rem;
    line-height: 90%;
  } @else if $size == h2 and $device == desktop {
    // H2 DESKTOP
    font-size: 4rem;
    line-height: 100%;
  } @else if $size == h2 and $device == mobile {
    // H2 MOBILE
    font-size: 2.5rem;
    line-height: 100%;
  } @else if $size == h3 and $device == desktop {
    // H3 DESKTOP
    font-size: 3rem;
    line-height: 110%;
  } @else if $size == h3 and $device == mobile {
    // H3 MOBILE
    font-size: 2rem;
    line-height: 110%;
  } @else if $size == h4 and $device == desktop {
    // H4 DESKTOP
    font-size: 2.25rem;
    line-height: 120%;
  } @else if $size == h4 and $device == mobile {
    // H4 MOBILE
    font-size: 1.75rem;
    line-height: 120%;
  } @else if $size == h5 and $device == desktop {
    // H5 DESKTOP
    font-size: 1.5rem;
    line-height: 130%;
  } @else if $size == h5 and $device == mobile {
    // H5 MOBILE
    font-size: 1.25rem;
    line-height: 130%;
  } @else if $size == h6 and $device == desktop {
    // H6 DESKTOP
    font-size: 1.125rem;
    line-height: 140%;
  } @else if $size == h6 and $device == mobile {
    // H6 MOBILE
    font-size: 1.125rem;
    line-height: 140%;
  } @else if $size == p1 and $device == desktop {
    // P1 DESKTOP
    font-size: 1.5rem;
    line-height: 130%;
  } @else if $size == p1 and $device == mobile {
    // P1 MOBILE
    font-size: 1.25rem;
    line-height: 130%;
  } @else if $size == p2 and $device == desktop {
    // P2 DESKTOP
    font-size: 1.125rem;
    line-height: 140%;
  } @else if $size == p2 and $device == mobile {
    // P2 MOBILE
    font-size: 1rem;
    line-height: 140%;
  } @else if $size == p3 and $device == desktop {
    // P3 DESKTOP
    font-size: 1rem;
    line-height: 150%;
  } @else if $size == p3 and $device == mobile {
    // P3 MOBILE
    font-size: 0.875rem;
    line-height: 150%;
  }

  @if $size == h1 or $size == h2 or $size == h3 {
    font-weight: 500;
  } @else {
    font-weight: 400;
  }
}

@mixin typeface-logo($device: desktop) {
  font-family: 'Luckiest Guy', cursive !important;

  @if $device == desktop {
    font-size: 7.5rem;
    line-height: 110%;
  } @else {
    font-size: 4.6875rem;
  }
}
