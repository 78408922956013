$shadow-btn-primary: 0px 15px 36px rgba(167, 150, 255, 0.24),
  0px 5.47525px 13.1406px rgba(167, 150, 255, 0.165596),
  0px 2.65813px 6.37951px rgba(167, 150, 255, 0.13351),
  0px 1.30307px 3.12736px rgba(167, 150, 255, 0.10649),
  0px 0.515234px 1.23656px rgba(167, 150, 255, 0.0744044);
$shadow-btn-secondary: 0px 9px 78px rgba(21, 27, 38, 0.68),
  0px 3.75998px 32.5865px rgba(21, 27, 38, 0.488821),
  0px 2.01027px 17.4223px rgba(21, 27, 38, 0.405353),
  0px 1.12694px 9.76681px rgba(21, 27, 38, 0.34),
  0px 0.598509px 5.18708px rgba(21, 27, 38, 0.274647),
  0px 0.249053px 2.15846px rgba(21, 27, 38, 0.191179);

$shadow-gamecard: 0px 45px 47px rgba(167, 150, 255, 0.05),
  0px 18.7999px 19.6355px rgba(167, 150, 255, 0.0359427),
  0px 10.0513px 10.4981px rgba(167, 150, 255, 0.0298054),
  0px 5.6347px 5.88513px rgba(167, 150, 255, 0.025),
  0px 2.99255px 3.12555px rgba(167, 150, 255, 0.0201946),
  0px 1.24527px 1.30061px rgba(167, 150, 255, 0.0140573);
