@use '/src/styles/partials/_colors.scss' as *;
@use '/src/styles/partials/_variables.scss' as *;
@use '/src/styles/partials/_helpers.scss' as *;
@use '/src/styles/partials/_responsive.scss' as *;
@use '/src/styles/partials/_lists.scss' as *;
@use 'sass:color';

@keyframes showNav {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes hideNav {
  0% {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
  }
  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}

.navbar {
  display: flex;
  width: 100%;
  height: $navSize;
  border-bottom: 1px solid $black-light;
  background: color.adjust($black-dark, $alpha: -0.5);
  backdrop-filter: blur(1rem);
  z-index: 1000;

  > div {
    height: $navSize;
    @include flexCenter(row);
  }

  .logo {
    position: relative;
    height: calc($navSize - 20px);
    width: 100%;
    max-width: $navSize;
  }

  .links {
    display: flex;
    align-items: center;
    gap: 1rem;

    @include media('>xl') {
      gap: 2.5rem;
    }

    a {
      color: $gray-7;
      text-decoration: none;
      transition: color 0.3s;
      height: $navSize;
      @include flexCenter(row);

      &:hover:not(.activeLink) {
        color: $white-light;
      }
      &.activeLink {
        color: $purple;
      }
    }
  }

  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-left: 1.5rem;

    @include media('<=md') {
      animation: hideNav 0.2s ease forwards;
      pointer-events: none;
      width: calc(100% - 2rem);
      position: fixed;
      top: calc($navSize + 0.25rem);
      left: 0;
      right: 0;
      z-index: 1050;
      flex-direction: column;
      background: color.adjust($black-dark, $alpha: -0.01);
      backdrop-filter: blur(1rem);
      margin-right: auto;
      margin-left: auto;
      border-radius: 0.25rem;

      .links {
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;

        a {
          width: 100%;
          height: auto;
          padding: 1rem 0;
        }
      }

      .userMenu {
        justify-content: center;
        margin-right: -2.125rem;
        height: auto;
        padding: 1rem 0;
      }

      &.menuOpen {
        pointer-events: all;
        animation: showNav 0.2s ease forwards;
        padding: 1rem;
      }
    }

    > a {
      font-weight: 300;
      padding-right: 1.5rem;
      padding-left: 1.5rem;

      @include media('>=md', '<lg') {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
  }

  .hamburgerMenu {
    margin-left: auto;

    @include media('>md') {
      display: none;
    }

    > button {
      padding: 0.75rem;
    }
  }

  .userMenu {
    position: relative;
    height: $navSize;
    display: flex;
    align-items: center;

    > button {
      position: relative;
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;

      .user {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        strong {
          transition: all 0.25s;
          color: $white-normal;
        }
        span {
          transition: all 0.25s;
          color: $gray-5;
          font-size: 90%;
        }
      }

      .caret {
        transition: all 0.25s;
        padding: 0 0.5rem;
        color: $white-dark;

        svg {
          stroke-width: 3px;
        }
      }

      &:hover {
        .user {
          strong {
            color: $purple-light;
          }
          span {
            color: $gray-7;
          }
        }
        .caret {
          padding: 0 0.5rem;
          color: $purple;
        }
      }
    }

    .menuOptions {
      position: absolute;
      top: 100%;
      right: 0;
      margin: 0.5rem;
      min-width: min(10rem, 80vw);

      &:after {
        position: absolute;
        content: ' ';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $gray-5 transparent;
        top: -10px;
        right: 2rem;
      }

      @include list() {
        z-index: 10000;
        li {
          button,
          a {
            cursor: pointer;
            text-align: left;
            display: block;
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            transition: all 0.2s;
            font-weight: 400;
          }

          &:hover {
            button,
            a {
              color: $white-normal;
            }
          }
        }
      }
    }
  }
}
