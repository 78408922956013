@use '/src/styles/partials/_colors.scss' as colors;
@use 'sass:color';

@mixin list() {
  list-style: none;

  li {
    text-align: center;
    background: colors.$black-light;
    border: 1px solid colors.$gray-5;
    overflow: hidden;
    border-top-width: 0;
    border-bottom-width: 0;

    &:first-child {
      border-radius: 0.35rem 0.35rem 0 0;
      border-top-width: 1px;
    }
    &:last-child {
      border-radius: 0 0 0.35rem 0.35rem;
      border-bottom-width: 1px;
    }

    &.active {
      box-shadow: 3px 0 0 colors.$purple-light inset;
      background: linear-gradient(
        90deg,
        colors.$purple-dark 0%,
        color.adjust(colors.$purple-light, $alpha: -1) 100%
      );

      button {
        color: colors.$white-normal;
        small {
          color: colors.$gray-6;
        }
      }
    }

    > button,
    > a {
      width: 100%;
      padding: 0.5rem 0.75rem;
      font-size: 90%;
      text-align: center;
      color: colors.$gray-5;

      small {
        transition: all 0.2s;
        margin-left: 0.25rem;
        color: colors.$gray-4;
      }

      &:hover small {
        color: colors.$gray-6;
      }
    }
  }

  @content;
}
