@use '/src/styles/partials/_colors.scss' as *;
@use '/src/styles/partials/_shadow.scss' as *;
@use '/src/styles/partials/_responsive.scss' as *;
@use '/src/styles/partials/_typography.scss' as *;
@use 'sass:color';

@mixin clearImage() {
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  width: auto !important;
  height: auto !important;
  max-width: none !important;
  max-height: none !important;
}

@mixin flexCenter($dir: column) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: $dir;
}

@mixin gameList() {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17.5rem, 1fr));
  grid-gap: 2rem;
  width: 100%;
  transition: all 0.15s;

  @include media('<md') {
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media('<sm') {
    grid-template-columns: 1fr;
  }

  > div {
    position: relative;
    transition: all 0.1s;
    overflow: hidden;
    border-radius: 0.5rem;

    &:hover,
    &.gameActive {
      transform: scale(1.05);
      filter: brightness(1.3);
      box-shadow: $shadow-btn-primary;

      .editBtn {
        opacity: 1;
      }
    }

    &.gameActive .gameCard {
      pointer-events: none;
    }

    .editBtn {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      z-index: 1;
      opacity: 0.6;
      transition: all 0.2s;
      padding: 0.25rem 0.5rem;
    }

    .gameCard {
      --height: 18.5rem;
      height: var(--height);
      background: $black-dark;
      border-radius: 0.5rem;
      position: relative;
      padding: 1rem 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      overflow: hidden;
      box-shadow: $shadow-gamecard;
      transition: all 0.1s;

      > strong {
        color: $white-light;
        @include typeface(h5);
        display: flex;
        align-items: center;
        margin-bottom: 0.25rem;
        z-index: 1;
      }

      > p {
        color: $gray-6;
        @include typeface(p3);
        z-index: 1;
        display: flex;
        justify-content: space-between;

        span:last-child {
          color: $gray-7;
        }
      }

      > div {
        z-index: 0;
        position: absolute;
        inset: 0;

        &:before {
          content: '';
          background: linear-gradient(
            180deg,
            color.adjust($black-normal, $alpha: -0.9) 40%,
            $black-dark 90%
          );
          width: 100%;
          height: 100%;
          position: absolute;
          inset: 0;
          z-index: 1;
        }

        > div {
          position: relative;
          height: var(--height);
        }

        img {
          object-fit: cover;
        }
      }
    }
  }
}
