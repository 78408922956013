$background: #101116;

// Purple
$purple: #9580ff;
$purple-light: #a796ff;
$purple-dark: #5b49b8;
$purple-6: #332673;
$purple-5: #5540bf;
$purple-4: #705cd6;
$purple-3: $purple;
$purple-2: $purple-light;
$purple-1: #bfb3ff;

// Black / White
$black-dark: #06090f;
$black-normal: #0f141f;
$black-light: #151b26;
$white-dark: #d8dbe3;
$white-normal: #e8ebf0;
$white-light: #f7f9fc;

// Gray colors
$gray-2: #2b2b2c;
$gray-3: #424344;
$gray-4: #595a5c;
$gray-5: #717274;
$gray-6: #888a8c;
$gray-7: #9ea0a3;

// Brand colors
@mixin brand-gradient-1($deg: 225deg) {
  background: linear-gradient($deg, $purple-light 0%, $purple 100%);
  background-color: $purple;
}
@mixin brand-gradient-2($deg: 225deg) {
  background: linear-gradient($deg, $purple 0%, $purple-dark 100%);
  background-color: $purple-dark;
}
@mixin brand-gradient-dark($deg: 225deg) {
  background: linear-gradient($deg, $black-normal 0%, $black-dark 100%);
  background-color: $black-dark;
}
@mixin brand-gradient-light($deg: 225deg) {
  background: linear-gradient($deg, $white-light 0%, $white-normal 100%);
  background-color: $white-normal;
}

// Gradients
@mixin gradient-1($deg: 225deg) {
  background: linear-gradient($deg, $purple-light 0%, $purple-dark 100%);
  background-color: $purple-light;
}
@mixin gradient-2($deg: 225deg) {
  background: linear-gradient($deg, $purple 0%, $purple-dark 100%);
  background-color: $purple;
}
@mixin gradient-3($deg: 225deg) {
  background: linear-gradient($deg, $black-normal 0%, $black-dark 100%);
  background-color: $black-normal;
}
@mixin gradient-4($deg: 225deg) {
  background: linear-gradient($deg, $white-light 0%, $white-normal 100%);
  background-color: $white-light;
}
@mixin gradient-5($deg: 225deg) {
  background: linear-gradient($deg, $gray-2 0%, $black-light 100%);
  background-color: $gray-2;
}
@mixin gold-gradient() {
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
  background-color: #fdb931;
}

// Auxiliary colors
$red-normal: #eb3d3d;
$red-dark: #d03333;
$red-light: #f05454;

$green-normal: #32d957;
$green-dark: #28bb49;
$green-light: #50e170;

$yellow-normal: #ffce52;
$yellow-dark: #d9b043;
$yellow-light: #ffd76b;
